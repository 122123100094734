<template>
  <div id="SignUpForm" class="bg-sign-up pt-5 pb-30">
    <div style="max-width:400px; margin: 0 auto;" class="grid-container">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <h2 class="sign-up-form-heading pt-3 pb-3">Sign up for 20% off</h2>
        </div>
        <div class="cell small-12">
          <input
            v-model="firstName"
            class="sign-up-form-input"
            type="text"
            placeholder="First Name" />
        </div>
        <div class="cell small-12 ">
          <input
            v-model="lastName"
            class="sign-up-form-input"
            type="text"
            placeholder="Last Name" />
        </div>
        <div class="cell small-12 ">
          <input
            v-model="email"
            class="sign-up-form-input"
            type="text"
            placeholder="Email" />
        </div>
        <div class="cell small-12 ">
          <input
            v-model="phone"
            class="sign-up-form-input"
            type="text"
            placeholder="Phone" />
        </div>
        <div class="cell small-12 ">
          <label class="sign-up-form-label">Select which garments you're interested in:</label>
        </div>
        <div class="cell small-12 ">
          <button
            v-if="categoryOne === true"
            @click="categoryOne = false"
            class="active sign-up-category-button">
            Polo shirt
          </button>
          <button
            v-if="categoryOne === false"
            @click="categoryOne = true"
            class="sign-up-category-button">
            Polo shirt
          </button>
          <button
            v-if="categoryTwo === true"
            @click="categoryTwo = false"
            class="active sign-up-category-button">
            T-Shirt
          </button>
          <button
            v-if="categoryTwo === false"
            @click="categoryTwo = true"
            class="sign-up-category-button">
            T-Shirt
          </button>
          <button
            v-if="categoryThree === true"
            @click="categoryThree = false"
            class="active sign-up-category-button">
            Trousers
          </button>
          <button
            v-if="categoryThree === false"
            @click="categoryThree = true"
            class="sign-up-category-button">
            Trousers
          </button>
          <button
            v-if="categoryFour === true"
            @click="categoryFour = false"
            class="active sign-up-category-button">
            Shorts
          </button>
          <button
            v-if="categoryFour === false"
            @click="categoryFour = true"
            class="sign-up-category-button">
            Shorts
          </button>
          <button
            v-if="categoryFive === true"
            @click="categoryFive = false"
            class="active sign-up-category-button">
            Sweatshirt
          </button>
          <button
            v-if="categoryFive === false"
            @click="categoryFive = true"
            class="sign-up-category-button">
            Sweatshirt
          </button>
          <button
            v-if="categorySix === true"
            @click="categorySix = false"
            class="active sign-up-category-button">
            Hoodie
          </button>
          <button
            v-if="categorySix === false"
            @click="categorySix = true"
            class="sign-up-category-button">
            Hoodie
          </button>
          <button
            v-if="categorySeven === true"
            @click="categorySeven = false"
            class="active sign-up-category-button">
            Fleece
          </button>
          <button
            v-if="categorySeven === false"
            @click="categorySeven = true"
            class="sign-up-category-button">
            Fleece
          </button>
          <button
            v-if="categoryEight === true"
            @click="categoryEight = false"
            class="active sign-up-category-button">
            Bodywarmer
          </button>
          <button
            v-if="categoryEight === false"
            @click="categoryEight = true"
            class="sign-up-category-button">
            Bodywarmer
          </button>
          <button
            v-if="categoryNine === true"
            @click="categoryNine = false"
            class="active sign-up-category-button">
            Jacket
          </button>
          <button
            v-if="categoryNine === false"
            @click="categoryNine = true"
            class="sign-up-category-button">
            Jacket
          </button>
          <button
            v-if="categoryTen === true"
            @click="categoryTen = false"
            class="active sign-up-category-button">
            Hi-Viz
          </button>
          <button
            v-if="categoryTen === false"
            @click="categoryTen = true"
            class="sign-up-category-button">
            Hi-Viz
          </button>
        </div>
        <div class="cell small-12">
          <textarea
            v-model="comments"
            class="sign-up-form-input mt-5"
            placeholder="Your comments"></textarea>
          <div class="text-center">
            <button
              @click="signUp"
              class="button expanded rounded-tall">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'SignUpForm',
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      comments: '',
      categoryOne: false,
      categoryTwo: false,
      categoryThree: false,
      categoryFour: false,
      categoryFive: false,
      categorySix: false,
      categorySeven: false,
      categoryEight: false,
      categoryNine: false,
      categoryTen: false,
    };
  },
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      });
    },
    signUp() {
      const postData = {};
      postData.first_name = this.firstName;
      postData.last_name = this.lastName;
      postData.email = this.email;
      postData.phone = this.phone;
      postData.comments = this.comments;
      postData.basketId = this.$store.state.basketId;
      postData.categories = this.getCategories();
      axios.post(`/customers/signUp/${this.productId}.json`, postData)
        .then((response) => {
          this.$store.commit('signedUp');
          this.$cookies.set('signedUp', 1);
          this.$store.commit('customer', response.data.customer);
          this.$cookies.set('customer', response.data.customer);
          window.scrollTo(0, 0);
        });
    },
    getCategories() {
      const categories = [];
      if (this.categoryOne === true) categories.push(1);
      if (this.categoryTwo === true) categories.push(2);
      if (this.categoryThree === true) categories.push(3);
      if (this.categoryFour === true) categories.push(4);
      if (this.categoryFive === true) categories.push(5);
      if (this.categorySix === true) categories.push(6);
      if (this.categorySeven === true) categories.push(7);
      if (this.categoryEight === true) categories.push(8);
      if (this.categoryNine === true) categories.push(9);
      if (this.categoryTen === true) categories.push(10);
      return categories;
    },
  },
};
</script>

<style lang="scss">
  .sign-up-form-heading {
    color: #FFF;
    font-family: 'rennersemi';
    font-size: 32px;
    text-transform: uppercase;
    text-align: center;
  }
  .bg-sign-up {
    background-color: #29287c;
  }
  input[type=text].sign-up-form-input,
  input[type=password].sign-up-form-input,
  textarea.sign-up-form-input {
    background-color: #FFF;
    border: 2px solid #000;
    border-radius: 10px;
    height: 60px;
  }
  textarea.sign-up-form-input {
    height: 100px;
  }
  .sign-up-form-label {
    color: #1dd699;
    font-family: 'rennersemi';
    font-size: 22px;
  }

  .sign-up-category-button {
    color: #FFF;
    border-radius: 15px;
    border: 2px solid #FFF;
    padding: 6px 0;
    margin: 5px 1%;
    width: 46%;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: #1dd699;
    }

    &.active {
      background-color: #1dd699;
    }
  }
</style>
