import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/customisation',
    name: 'Customisation',
    component: () => import('../views/Customisation.vue'),
  },
  {
    path: '/edit-design/:id',
    name: 'EditCustomisation',
    component: () => import('../views/EditCustomisation.vue'),
  },
  {
    path: '/basket',
    name: 'Basket',
    component: () => import('../views/Basket.vue'),
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('../views/Checkout.vue'),
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('../views/Payment.vue'),
  },
  {
    path: '/thankyou/:id',
    name: 'Thankyou',
    component: () => import('../views/Thankyou.vue'),
  },
  {
    path: '/invoice/:id',
    name: 'Invoice',
    component: () => import('../views/Invoice.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword.vue'),
  },
  {
    path: '/reset-password/:hash/:id',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue'),
  },
  {
    path: '/uploaded-artwork',
    name: 'UploadedArtwork',
    component: () => import('../views/UploadedArtwork.vue'),
  },
  {
    path: '/previous-orders',
    name: 'PreviousOrders',
    component: () => import('../views/PreviousOrders.vue'),
  },
  /*
  {
    path: '/plan',
    name: 'Plan',
    component: () => import('../views/Plan.vue'),
    beforeEnter(to, from, next) {
      if (store.state.userid) {
        next();
      } else {
        next('/');
      }
    },
  },
  */
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to) {
    if (to.name === 'Customisation') {
      return { x: 0, y: 85 };
    }
    if (to.name === 'Basket') {
      return { x: 0, y: 85 };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
