import axios from 'axios';

export default axios.create({
  // Dev
  baseURL: 'https://api-customised-clothing.mmandb-dev.co.uk',
  // Prod URL: 'https://viewing-room-api.messums.com',
  headers: {
    'Content-type': 'application/json',
  },
});
